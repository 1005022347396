body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
	border-collapse: collapse;
  width: 80%;
  margin: auto;
}

table th {
  background-color: aqua;
  border: 1px solid;
}

table td {
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  background-color: lightgreen;
  /* text-align: left; */
}

.issue-select {
  width: 20%;
  margin-right: 20px;
  display: inline-block;
}

.activity-select {
  width: 20%;
  margin-right: 20px;
  display: inline-block;
}

.spent-hours {
  width: 20%;
  margin-right: 20px;
  display: inline-block;
  border-color: #ccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  height: 38px;
  text-align: center;
}

.spent-hours:focus {
  outline-color: #2684ff;
}

button[type=submit] {
  background: transparent;
  border: none;
  background-color: #cccccc;
  height: 38px;
  box-sizing: border-box;
  padding: 5px 20px;
}

button[type=submit]:hover {
  background-color: #ddd;
}

form {
  width: 80%;
  margin: auto;
}